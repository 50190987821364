import { useEffect, useState } from 'react';
import TarsServices from '../Services/TarsServices';
import { GenerateJWTSessionCustomer } from '../Services/SoraService';
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';

const AccessLink = () => {
    const [loading, setLoading] = useState(false);
    const { CreateCustomerFromLink } = TarsServices();
    const navigate = useNavigate();
    const { getStorage, setStorage } = useCreditRequestAuth();

    let request = getStorage("request");

    useEffect(() => {
        setLoading(true);
        const queryParameters = new URLSearchParams(window.location.search)

        const id = queryParameters.get("id")
        const token = queryParameters.get("token")

        if (id && token) {
            console.log(id)

            CreateCustomerFromLink(id, token).then((response) => {
                console.log(response)
                if (response.status === 200 || response.status === 201) {

                    let data = JSON.parse(response.data.data);

                    GenerateJWTSessionCustomer({ token: data.token }).then(resjwt => {
                        setLoading(false);
                        if (resjwt.status === 200 || resjwt.status === 201) {

                            setStorage("request", {
                                ...request,
                                state: 9,
                                identityValidated: true
                            });

                            setStorage("creditRequestToken", JSON.parse(resjwt.data.data).Token, { path: "/" })
                            setStorage("expiredTime", Date.parse(JSON.parse(resjwt.data.data).ExpiredTime), { path: "/" })

                            navigate("/Dashboard");


                        } else {
                            Swal.fire({
                                html: resjwt.data.message,
                                showCancelButton: false,
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false,
                                icon: 'error'
                            })

                        }

                    })
                }
                else {
                    setLoading(false);
                    Swal.fire({
                        html: response.data.message,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        icon: 'error'
                    })

                }
            });


        } else {
            setLoading(false);
            Swal.fire({
                title: "Acceso invalido",
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                icon: 'error'
            })
        }

    }, []);



    return (
        <>
            {loading &&
                (
                    <div className="my-loader">
                        <div className="my-loader-container">
                            <img src={imgLoader} alt="loader"></img>

                        </div>
                    </div>
                )}
        </>
    )
}



export default AccessLink;