import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useCreditRequestAuth } from '../Context/useCreditRequestAuth';
import { useCreditRequest } from '../Context/useCreditRequest';
import '../StyleSheets/Esign.css'
import useForm from '../Hooks/useForm';
import imgLoader from '../Images/MeddipayLoaderEllipsis.gif'
// import OlimpiaServices from "../Services/OlimpiaServices";
import AutenticServices from "../Services/AutenticService";
import GogolServices from "../Services/GogolServices";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Button, Modal } from 'react-bootstrap';
import ViewSDKClient from './ViewSDKClient'
import BellServices from "../Services/BellServices";

const validateForm = (form) => {

    let errors = {};

    if (!form.digit1.trim()) { errors.digit1 = "El campo digit1 es requerido" }
    if (!form.digit2.trim()) { errors.digit2 = "El campo digit2 es requerido" }
    if (!form.digit3.trim()) { errors.digit3 = "El campo digit3 es requerido" }
    if (!form.digit4.trim()) { errors.digit4 = "El campo digit4 es requerido" }
    // if (!form.digit5.trim()) { errors.digit5 = "El campo digit5 es requerido" }
    // if (!form.digit6.trim()) { errors.digit6 = "El campo digit6 es requerido" }

    return errors;

};

const PdfViewer = ({ base64, id, fileName }) => {


    useEffect(() => {
        var byteCharacters = atob(base64);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);

        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {

            viewSDKClient.previewFile(
                id,
                {
                    showAnnotationTools: true,
                    showLeftHandPanel: true,
                    showPageControls: true,
                    showDownloadPDF: true,
                    showPrintPDF: true
                },
                fileURL,
                fileName
            );

        });
    }, []);

    return <div style={{ width: "100%", height: "600px" }} id={id}></div>
}

const ESign = () => {

    const [sending, setSending] = useState(false);
    const { getStorage, setStorage } = useCreditRequestAuth();
    const { setLogoSize } = useCreditRequest();
    let request = getStorage("request");
    const inputReference = useRef(null);
    const initialForm = { digit1: "", digit2: "", digit3: "", digit4: "", digit5: "", digit6: "" };
    const { form, errors, handleChangeOtpSign, handleSubmit, resetForm } = useForm(initialForm, validateForm);
    const formRef = useRef(null);
    const { PrepareAndNotify, OTPAndSignContract, ResendOTP } = AutenticServices();
    const { GetPDFforAutentic } = GogolServices();
    const MySwal = withReactContent(Swal);
    const [pdfContrato, setPdfContrato] = useState("");
    const [pdfPagare, setPdfPagare] = useState("");
    const [otpSended, setOtpSended] = useState(false);


    const [transaccionId, setTransaccionId] = useState("");
    const [celular, setCelular] = useState("");
    const [email, setEmail] = useState("");
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [resendTime, setResendTime] = useState(60);
    const { SendTemplateWhatsApp } = BellServices();

    const navigate = useNavigate();

    useEffect(() => {



        setLogoSize({ width: "240px", marginTop: "40px", marginBottom: "30px" });

        window.addEventListener('paste', handlePasteAnywhere);


        setSending(true);

        let a = GetPDFforAutentic(request.COFI, request.IdApplication);
        let b = GetPDFforAutentic(request.PYNO, request.IdApplication);


        Promise.all([a, b]).then((values) => {
            setSending(false);

            if (values[0].status === 200 && values[1].status === 200) {
                setPdfContrato(values[0].data.data)
                setPdfPagare(values[1].data.data)
            } else {
                MySwal.fire({
                    html: values[0].status !== 200 ? values[0].data.responseText : values[1].data.responseText,
                    icon: 'error'
                })
            }

        })



    }, []);


    const sendOtp = () => {

        setSending(true);

        PrepareAndNotify(request.IdApplication).then(res => {
            setSending(false);

            if (res.status === 200) {

                setOtpSended(true);


                let data = res.data.data;

                setTransaccionId(data.transactionIdOTP)
                setCelular(data.phone)
                setEmail(data.email)

                setPdfContrato(data.documents.find(el => el.fileType === 2).fileContent)

                setPdfPagare(data.documents.find(el => el.fileType === 1).fileContent)

                setResendTime(60);

                var timer = setInterval(() => {

                    setResendTime(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTime(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            } else {
                setSending(false);
                MySwal.fire({
                    html: res.data.message,
                    icon: 'error'
                })
            }
        });

    }
    useEffect(() => () => {
        window.removeEventListener('paste', handlePasteAnywhere);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePasteAnywhere = event => {
        let text = event.clipboardData.getData('text');

        if (text.length === 4 && !isNaN(text)) {

            handleChangeOtpSign({ target: { name: "digit1", value: text[0] } })
            handleChangeOtpSign({ target: { name: "digit2", value: text[1] } })
            handleChangeOtpSign({ target: { name: "digit3", value: text[2] } })
            handleChangeOtpSign({ target: { name: "digit4", value: text[3] } })
            // handleChangeOtp({ target: { name: "digit5", value: text[4] } })
            // handleChangeOtp({ target: { name: "digit6", value: text[5] } })
        }

    };

    // useEffect(() => {
    //     let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4;// + form.digit5 + form.digit6;

    //     // if (OTP.length === 4) {
    //     //     formRef.current.click();
    //     // }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [form.digit4]);


    const reSendOtp = () => {

        setSending(true);

        ResendOTP({
            transactionIdOTP: transaccionId,
            idApplication: request.IdApplication,
            email: email,
            phone: celular
        }).then(res => {

            setSending(false);

            if (res.status === 200) {

                let data = res.data.data;

                setTransaccionId(data.transactionIdOTP)

                setResendTime(60);

                var timer = setInterval(() => {

                    setResendTime(t => {
                        const modifiedValue = t - 1;
                        if (modifiedValue <= 0) {
                            clearInterval(timer);
                            setResendTime(null);
                        }
                        return modifiedValue;
                    });


                }, 1000);

            } else {

                MySwal.fire({
                    html: res.data.message,
                    icon: 'error'
                })
            }
        })
    }

    const submitForm = (form, error) => {

        if (!error) {
            setSending(true);

            let OTP = form.digit1 + form.digit2 + form.digit3 + form.digit4;// + form.digit5 + form.digit6;

            let body = {
                codeOTP: OTP,
                transactionId: transaccionId,
                idApplication: request.IdApplication
            }

            OTPAndSignContract(body).then(res => {
                setSending(false);

                if (res.status === 200) {
                    // let data = JSON.parse(res.data.data)

                    let bodyBell1 = {
                        templateId: "WAOPC",
                        variables: null,
                        destination: "+57" + celular,
                        region: "co",
                        lang: "co-co"
                    }

                    // let bodyBell2 = {
                    //     templateId: "WAWPO",
                    //     variables: [
                    //         {
                    //             position: "1",
                    //             value: request.FullName
                    //         },
                    //         {
                    //             position: "2",
                    //             value: request.invitation.CommercialName
                    //         },
                    //         {
                    //             position: "3",
                    //             value: request.Reference
                    //         },
                    //         {
                    //             position: "4",
                    //             value: request.invitation.LabelAmountFinance
                    //         },
                    //         {
                    //             position: "5",
                    //             value: request.LabelRegularInstalment
                    //         },
                    //         {
                    //             position: "6",
                    //             value: request.LabelTerm
                    //         },
                    //         {
                    //             position: "7",
                    //             value: request.PayDay
                    //         },
                    //         {
                    //             position: "8",
                    //             value: request.FirstInstalmentDate
                    //         },
                    //         {
                    //             position: "9",
                    //             value: "payment?reference=" + request.Reference
                    //         }
                    //     ],
                    //     destination: "+57" + celular,
                    //     region: "co",
                    //     lang: "co-co"
                    // }

                    SendTemplateWhatsApp(bodyBell1).then(res => { });
                    //SendTemplateWhatsApp(bodyBell2).then(res => { });

                    setStorage("request", {
                        ...request,
                        state: 9,
                        creditApproved: 1,
                        creditApprovedMessage: res.data.message
                    });

                    navigate("/Dashboard");

                    // MySwal.fire({
                    //     html: res.data.message,
                    //     confirmButtonText: "Entendido",
                    //     allowOutsideClick: false,
                    //     allowEscapeKey: false
                    // }).then((result) => {
                    //     if (result.isConfirmed) {

                    //         setStorage("request", {
                    //             ...request,
                    //             state: 9,
                    //             creditApproved: 1,
                    //             creditApprovedMessage: res.data.message
                    //         });

                    //         navigate("/Dashboard");
                    //     }
                    // })



                } else {
                    setSending(false);
                    MySwal.fire({
                        html: res.data.message,
                        icon: 'error'
                    })
                }
            })
        }

    }


    return (
        <>


            <Modal show={show1} onHide={handleClose1} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body>
                    <PdfViewer base64={pdfContrato} id="adobe-contrato" fileName="Contrato.pdf" />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>Cerrar</Button>
                </Modal.Footer>
            </Modal>







            <Modal show={show2} onHide={handleClose2} dialogClassName="modal-aut">
                <Modal.Header closeButton>

                </Modal.Header>

                <Modal.Body>
                    <PdfViewer base64={pdfPagare} id="adobe-pagare" fileName="Pagare.pdf" />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>Cerrar</Button>
                </Modal.Footer>
            </Modal>


            <form onSubmit={(e) => handleSubmit(e, submitForm)}>
                <table id="table-sign">
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center", color: "black", padding: "0px" }} colSpan={6}>
                                <h3>Documentos y firma</h3>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <div className="divBox-esign" onClick={handleShow1}>

                                    <div className="container-fluid">
                                        <div >

                                            <div style={{ display: "inline-flex" }}>
                                                <i className="bi bi-file-text" style={{ fontSize: "35px", marginRight: "20px", color: "#00A9EB" }}></i>
                                                <div style={{ padding: "15px" }}> Contrato de financiación</div>


                                            </div>



                                        </div>
                                    </div>



                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <div className="divBox-esign" onClick={handleShow2}>

                                    <div className="container-fluid">
                                        <div >

                                            <div style={{ display: "inline-flex" }}>
                                                <i className="bi bi-file-text" style={{ fontSize: "35px", marginRight: "20px", color: "#00A9EB" }}></i>

                                                <div style={{ padding: "15px" }}>Pagaré</div>

                                            </div>



                                        </div>
                                    </div>



                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={6}>
                                <br />
                            </td>
                        </tr>
                        {!otpSended ? (<>
                            <tr>
                                <td colSpan={6}>
                                    <button type="button" disabled={sending} className="btn btn-mp" style={{ marginRight: "20px", marginTop: "25px" }} onClick={sendOtp}>Firmar</button>
                                </td>
                            </tr>
                        </>) : (
                            <>
                                <tr>
                                    <td colSpan={6}>
                                        Por favor, a continuación, ingrese el código para proceder con la firma de los documentos que le ha tenido que llegar tanto al correo electrónico como al celular registrado.
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <u style={{ color: resendTime ? "gray" : "blue", cursor: resendTime ? "not-allowed" : "pointer" }} onClick={resendTime ? undefined : reSendOtp}>Reenviar código</u>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        {resendTime}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={6}>
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="blank-td"></td>
                                    <td>
                                        <input ref={inputReference} className={`form-control input-otp ${errors.digit1 && "is-invalid"}`} pattern="[0-9]*" name="digit1" type="number" value={form.digit1} onChange={handleChangeOtpSign} />
                                    </td>
                                    <td>
                                        <input className={`form-control input-otp ${errors.digit2 && "is-invalid"}`} pattern="[0-9]*" name="digit2" type="number" value={form.digit2} onChange={handleChangeOtpSign} />
                                    </td>
                                    <td>
                                        <input className={`form-control input-otp ${errors.digit3 && "is-invalid"}`} pattern="[0-9]*" name="digit3" type="number" value={form.digit3} onChange={handleChangeOtpSign} />
                                    </td>
                                    <td>
                                        <input className={`form-control input-otp ${errors.digit4 && "is-invalid"}`} pattern="[0-9]*" name="digit4" type="number" value={form.digit4} onChange={handleChangeOtpSign} />
                                    </td>
                                    <td className="blank-td"></td>

                                </tr>
                            </>)}

                        <tr>
                            <td colSpan={6}>
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: "center", padding: "10px" }} colSpan={6}>
                                <Link to="/ApplicationRequest" onClick={() => {
                                    setStorage("request", {
                                        ...request, state: 10
                                    })
                                }}>
                                    <button type="button" disabled={sending} className="btn btn-mp-back" style={{ marginRight: "20px", marginTop: "50px" }}>Regresar</button>
                                </Link>

                                {otpSended && (<input type="submit" value="Continuar" disabled={sending} className="btn btn-mp" ref={formRef} style={{ marginTop: "50px" }} />)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {sending &&
                                    (
                                        <div className="my-loader">
                                            <div className="my-loader-container">
                                                <img src={imgLoader} alt="loader"></img>

                                            </div>
                                        </div>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>


        </>

    );
}


export default ESign;